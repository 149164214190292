import React, {
    useState,
} from "react";
import {
    useParams,
} from "react-router-dom";
import {
    Button,
    Container,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import {
    Loop,
} from '@material-ui/icons';
import {
    oauthRedirect,
} from "../api/google-api";
import {
    getHead,
    lookupProfile,
} from "../api/minecraft-api";

export default function Join(props) {
    const {server} = useParams();
    const [minecraftUsernameInput, setMinecraftUsernameInput] = useState("");
    const [error, setError] = useState("");

    function linkYouTube() {
        document.location.href = oauthRedirect(server);
    }

    async function linkMinecraft() {
        setError("");
        const profile = await lookupProfile(minecraftUsernameInput);
        if (profile) {
            props.mmcapi.current.setMinecraftUUID(profile.uuid);
        } else {
            setError("Could not find a Minecraft account with that name");
        }
    }

    if (props.loading) {
        return (
            <div className="spinner">
                <Loop />
            </div>
        );
    }
    if (!props.name) {
        return (
            <Container>
                <Typography variant="h4">Sign in to Your YouTube Account</Typography>
                <Typography variant="body1">Sign into the YouTube account you membered <strong>Beatani</strong> with.</Typography>
                <Button variant="contained" color="primary" onClick={linkYouTube} className="google">
                    <img src="/g-logo.png" className="glogo"/>Sign in with Google
                </Button>
            </Container>
        );
    }
    if (!props.membered) {
        return (
            <Container>
                <Typography variant="h4">You do not have a sufficient membership level</Typography>
                <Typography variant="body1"><strong>Beatani's</strong> server requires you to have a membership level of <strong>My Family</strong> or higher.</Typography>
                <Typography variant="body1">Membership data is synced periodically. If you just joined <strong>My Family</strong> or higher you may need to wait before you can proceed.</Typography>
                <a target="_target" href="https://www.youtube.com/channel/UCArDBJWZGPBtVeXwr4ROddA/join"><Button variant="contained" color="primary">Join Beatani's Membership</Button></a>
            </Container>
        );
    }
    if (!props.minecraftUsername) {
        return (
            <Container>
                <Typography variant="h4">Whitelist Your Minecraft Account</Typography>
                <Typography variant="body1">What username would you like whitelisted for <strong>Beatani's</strong> server?</Typography>
                <div className="mcform">
                    <TextField label="Minecraft Username" className="mcname" variant="outlined" value={minecraftUsernameInput} onChange={(event) => setMinecraftUsernameInput(event.target.value)}/>
                    <Button variant="contained" color="primary" onClick={linkMinecraft}>Whitelist</Button>
                    { error !== "" ? <Paper className="mcerror"><Typography variant="body1">{error}</Typography></Paper> : <></> }
                </div>
            </Container>
        )
    }
    return (
        <Container>
            <Typography variant="h4">Whitelist Your Minecraft Account</Typography>
            <Typography variant="body1">What username would you like whitelisted for <strong>Beatani's</strong> server?</Typography>
            <div className="mcform">
                <TextField label="Minecraft Username" className="mcname" variant="outlined" value={minecraftUsernameInput} onChange={(event) => setMinecraftUsernameInput(event.target.value)}/>
                <Button variant="contained" color="primary" onClick={linkMinecraft}>Whitelist</Button>
            </div>
            { error !== "" ? <Paper className="mcerror"><Typography variant="body1">{error}</Typography></Paper> : <></> }
            <Typography variant="h5">Currently Whitelisted As</Typography>
            <div className="account">
                <img src={props.minecraftUsername ? getHead(props.minecraftUsername) : ""} alt=""/>
                <Typography variant="body1">{props.minecraftUsername}</Typography>
            </div>
        </Container>
    );
}
const api = CONFIG.membersmc.api;
const apiWS = CONFIG.membersmc.apiWS;

const defaultTimeout = 1000;
const maxTimeout = 10 * 1000;

async function sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

async function retry(mmcapi) {
  if (mmcapi.disconnected) {
    return;
  }
  await sleep((mmcapi.timeout * Math.random() + mmcapi.timeout) / 2);
  mmcapi.timeout = Math.min(mmcapi.timeout * 2, maxTimeout);
  return await mmcapi.connect();
}

async function attemptConnect(mmcapi) {
  const request = await fetch(api + "/token");
  const token = await request.json();
  // Check if we get a failure response from the api
  if (token.access_token === null) {
    // If we are not logged in do not retry
    return false;
  }
  // If the request failed stop trying
  if (!token.access_token) {
    return true;
  }
  if (mmcapi.disconnected) {
    throw Error("Disconnected during connect");
  }
  const socket = new WebSocket(apiWS + "/api");
  socket.addEventListener("open", () => {
    mmcapi.timeout = defaultTimeout;
    socket.send(JSON.stringify({type: "AuthorizationMessage", access_token: token.access_token}));
    for (const message of mmcapi.messageQueue) {
      socket.send(message);
    }
    mmcapi.messageQueue = [];
    mmcapi.socket = socket;
  });
  socket.addEventListener("message", (event) => {
    const object = JSON.parse(event.data);
    for (const listener of mmcapi.listeners) {
      listener(object);
    }
  });
  socket.addEventListener("close", (event) => {
    retry(mmcapi);
  });
  return true;
}

export default class MMCAPI {
  constructor() {
    this.disconnected = false;
    this.listeners = [];
    this.messageQueue = [];
    this.socket = null;
    this.timeout = defaultTimeout;
  }

  connect() {
    this.disconnected = false;
    return attemptConnect(this).catch(() => {
      return retry(this);
    });
  }

  disconnect() {
    this.disconnected = true;
    if (this.socket) {
      this.socket.close();
    }
  }

  send(message) {
    const string = JSON.stringify(message);
    if (this.socket) {
      this.socket.send(string);
    }
    this.messageQueue.push(string);
  }

  setMinecraftUUID(uuid) {
    this.send({type: "SetMinecraftUUID", uuid: uuid});
  }

  addListener(listener) {
    this.listeners.push(listener);
  }

  async logout() {
    await fetch(api + "/logout");
  }
}
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useParams,
} from "react-router-dom";
import {
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  oauthRedirect,
} from "../api/google-api";
import {
  getHead,
  lookupProfile,
} from "../api/minecraft-api";
import MMCAPI from "../api/mmc-api";

export default function Join() {
  const {server} = useParams();
  const [name, setName] = useState("");
  const [iconURL, setIconURL] = useState("");
  const [minecraftUsername, setMinecraftUsername] = useState("");
  const [minecraftUsernameInput, setMinecraftUsernameInput] = useState("");
  const mmcapi = useRef(new MMCAPI());

  useEffect(() => {
    const mmcapiInstance = mmcapi.current;
    mmcapiInstance.addListener(messageListener);
    mmcapiInstance.connect();
    return () => mmcapiInstance.disconnect();
  }, []);

  function messageListener(message) {
    console.log(message);
    switch (message.type) {
      case "UserMessage":
        if (message.name) {
          setName(message.name);
        }
        if (message.iconURL) {
          setIconURL(message.iconURL);
        }
        if (message.minecraftUUID) {
          lookupProfile(message.minecraftUUID).then(profile => setMinecraftUsername(profile.username))
        }
        break;
      default:
        break;
    }
  }

  function linkYouTube() {
    document.location.href = oauthRedirect(server, true);
  }

  return (
      <div>
        <Container>
          <Typography variant="h2">Creator Dashboard</Typography>
          <Typography variant="body1">Sign into a YouTube account which has membership enabled.</Typography>
          <Button variant="contained" color="primary" onClick={linkYouTube}>Link</Button>
          <img src={iconURL} alt="" />
          <Typography variant="body1">{name}</Typography>
        </Container>
      </div>
  );
}
const api = CONFIG.membersmc.api
const clientId = CONFIG.google.clientId;

const memberScopes = "https%3A//www.googleapis.com/auth/youtube.readonly";
const creatorScopes = "https%3A//www.googleapis.com/auth/userinfo.email https%3A//www.googleapis.com/auth/youtube.readonly https%3A//www.googleapis.com/auth/youtube.channel-memberships.creator";

export function oauthRedirect(state, creator = false) {
  return "https://accounts.google.com/o/oauth2/v2/auth?" +
    "client_id=" + clientId + "&" +
    "redirect_uri=" + api + "/oauth&" +
    "response_type=code&" +
    "scope=" + (creator ? creatorScopes : memberScopes) + "&" +
    "access_type="  + (creator ? "offline" : "online") + "&" +
    "state=" + state;
}
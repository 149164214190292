export function getHead(username) {
  return "https://www.mc-heads.net/avatar/" + username;
}

export async function lookupProfile(id) {
  const response = await fetch("https://api.ashcon.app/mojang/v2/user/" + id);
  if (response.status !== 200) {
    return;
  }
  return await response.json();
}
import React from "react";
import {
    Container,
    Typography,
} from "@material-ui/core";

export default function PrivacyPolicy() {
    return (
        <Container>
            <Typography variant="h3">Privacy Policy</Typography>
            <Typography variant="h4">Collected Information</Typography>
            <Typography variant="h5">Visitors</Typography>
            <Typography variant="body1">Data such as your IP address, user agent, and what pages you visit may be logged in order to improve the service and prevent abuse</Typography>
            <Typography variant="h5">Members</Typography>
            <Typography variant="body1">YouTube Channel Id: Your channel id is collected in order to link it with your minecraft account.</Typography>
            <Typography variant="body1">Minecraft Account UUID: Your Minecraft account UUID is collected in order to link it with your YouTube account.</Typography>
            <Typography variant="h5">Creators</Typography>
            <Typography variant="body1">YouTube Channel Id: Your channel id is collected in order to associate it with your membership data.</Typography>
            <Typography variant="body1">YouTube Membership Levels: Your membership levels are collected in order to provide you the option for what level the server should be accessible from.</Typography>
            <Typography variant="body1">YouTube Membership Members Channel Id and Membership Levels: The channel ids and membership levels of your members are collected in order to know who to give access to the server to.</Typography>
            <Typography variant="body1">Email: Your email is collected, so that you can be contacted in case of any problems with the service.</Typography>
            <Typography variant="h4">Sharing of Information</Typography>
            <Typography variant="body1">In order for the service to work current membership data from a creator is shared with a Minecraft server that they have approved by sharing their API token.</Typography>
        </Container>
    );
}
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";
import {
  createMuiTheme,
  AppBar,
  Button,
  CssBaseline,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core"
import {
  lookupProfile,
} from "../api/minecraft-api";
import MMCAPI from "../api/mmc-api";
import Dashboard from "./Dashboard";
import Home from "./Home";
import Join from "./Join";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import "../style.css";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [iconURL, setIconURL] = useState("");
  const [membered, setMembered] = useState(false);
  const [minecraftUsername, setMinecraftUsername] = useState("");
  const [anchorElement, setAnchorElement] = useState(null);
  const mmcapi = useRef(new MMCAPI());

  useEffect(() => {
    const mmcapiInstance = mmcapi.current;
    mmcapiInstance.addListener(messageListener);
    mmcapiInstance.connect().then(success => {
      // If logging in fails stop trying to load
      if (!success) {
        setLoading(false);
      }
    });
    return () => mmcapiInstance.disconnect();
  }, []);

  function messageListener(message) {
    switch (message.type) {
      case "UserMessage":
        if (message.name) {
          setName(message.name);
        }
        if (message.iconURL) {
          setIconURL(message.iconURL);
        }
        if (message.membered) {
          setMembered(message.membered);
        }
        if (message.minecraftUUID) {
          lookupProfile(message.minecraftUUID).then(profile => {
            setMinecraftUsername(profile.username);
          }).finally(() => setLoading(false));
        }
        else {
          setLoading(false);
        }
        break;
      default:
        break;
    }
  }

  function handleOpen(event) {
    setAnchorElement(event.target);
  }

  function handleClose() {
    setAnchorElement(null);
  }

  function handleLogout() {
    handleClose();
    console.log(mmcapi.current);
    mmcapi.current.logout().then(() => {
      mmcapi.current.disconnect();
      setName("");
      setIconURL("");
      setMembered(false);
      setMinecraftUsername("");
    });
  }

  const theme = createMuiTheme({
    palette: {
      type: "dark"
    }
  });
  return (
      <Router>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="wrapper">
            <AppBar position="static" color="default">
              <Toolbar>
                <Link to="/">
                <Button color="inherit">
                  <img src="mmc.png" height={32} />
                </Button>
                </Link>
                <Typography variant="h5">MembersMC</Typography>
                {name !== "" ?
                    <Button id="profile" color="inherit" onClick={handleOpen}>
                      <Typography variant="body1">{name}</Typography>
                      <img src={iconURL} height={32} />
                    </Button> : <></>}
                <Menu anchorEl={anchorElement} keepMounted open={Boolean(anchorElement)} onClose={handleClose}>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </Toolbar>
            </AppBar>
            <div className="page">
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/dashboard">
                  <Dashboard />
                </Route>
                <Route path="/privacy-policy">
                  <PrivacyPolicy />
                </Route>
                <Route path="/terms-of-service">
                  <TermsOfService />
                </Route>
                <Route path="/:server">
                  <Join mmcapi={mmcapi} loading={loading} name={name} membered={membered} minecraftUsername={minecraftUsername} />
                </Route>
              </Switch>
            </div>
            <div className="footer">
              <Link to="/privacy-policy">
                <Typography variant="body1">Privacy Policy</Typography>
              </Link>
              <Link to="/terms-of-service">
                <Typography variant="body1">Terms of Service</Typography>
              </Link>
            </div>
          </div>
        </ThemeProvider>
      </Router>
  );
}
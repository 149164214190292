import React from "react";
import {
    Container,
    Typography,
} from "@material-ui/core";
import {Link} from "react-router-dom";

export default function TermsOfService() {
    return (
        <Container>
            <Typography variant="h3">Terms of Service</Typography>
            <Typography variant="h4">User Agreement</Typography>
            <Typography variant="body1">Do not abuse the service.</Typography>
            <Typography variant="h4">Privacy Policy</Typography>
            <Typography variant="body1">Information about the privacy policy can be found <Link to="/privacy-policy">here</Link></Typography>
        </Container>
    );
}
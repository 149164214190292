import React from "react";
import {
    Container,
    Grid,
    Paper,
    Typography,
} from "@material-ui/core";
import {
    Build,
    Storage,
    Star,
} from '@material-ui/icons';

export default function Home() {
    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3">Offer Minecraft YouTube Membership Perks</Typography>
                    <Typography variant="body1">This service will allow you to add YouTube membership perks to your Minecraft server.</Typography>
                    <Typography variant="body1">Channel members will be able to easily link their YouTube account and Minecraft account to get their perks.</Typography>
                    <Typography variant="body1">If you are interested in giving out members only perks on your Minecraft server without you doing manual work this service is for you.</Typography>
                </Grid>
                    <Grid item sm={4} xs={12}>
                        <Paper>
                            <Build fontSize="large" />
                            <Typography variant="h5">Easy Setup</Typography>
                            <Typography variant="body1">Focus on creating content. Not managing a whitelist.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Paper>
                            <Storage fontSize="large" />
                            <Typography variant="h5">Configurable</Typography>
                            <Typography variant="body1">Pick what tiers get the perks.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                        <Paper>
                            <Star fontSize="large" />
                            <Typography variant="h5">Ranks</Typography>
                            <Typography variant="body1">If you have a public server you can instead have a permission rank for members.</Typography>
                        </Paper>
                    </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Coming Soon</Typography>
                </Grid>
            </Grid>
        </Container>
    );
}